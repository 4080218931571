import React, { useState, useCallback, useContext } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { getEntityUrl, getTranslatedTopbarEntities, getTranslatedEntityLink } from "../../util";

import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import { Link, useStaticQuery, graphql } from "gatsby";
import { IconButton, Slide } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Switch from '@mui/material/Switch';

import { useTheme } from "@mui/material/styles";

import { ColorModeContext } from '../../components/Layout';



const MainMenu = (props) => {
    /* TODO - util this */
    const { allStrapiAppSettings: settings, allStrapiEntities: entities } = useStaticQuery(staticQuery);
    const { pageContext, entity, locales } = props;
    const { locale: curLocale, defaultLocale } = pageContext || {};
    const translatedTopbarEntites = getTranslatedTopbarEntities(settings, entities, curLocale);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = useCallback((e) => {
        if (e.target.nodeName !== 'INPUT')//dont hide on darkmode-switch - TODO - y?
            setAnchorEl(null);
        e.stopPropagation();
    }, [setAnchorEl]);

    const image = settings?.nodes[0]?.logo ? getImage(settings.nodes[0].logo.localFile) : null;

    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    return (
        <Root>{translatedTopbarEntites.length > 0 && <>
            <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
                size="large">
                <MenuIcon />
            </IconButton>
            <Slide direction="down" in={open} mountOnEnter unmountOnExit>

                <div className={classes.root} onClick={handleClose} >
                    <div style={{ display: 'flex' }}>
                        <IconButton
                            edge="end"
                            className={classes.closeButton}
                            color="inherit"
                            aria-label="menu"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className={classes.menu} onClick={handleClose}>

                        {image !== null ?
                            <div className={classes.item}><GatsbyImage className={classes.logo} image={image} alt="logo" layout="fullWidth" /></div> : <></>}

                        {translatedTopbarEntites?.map(e =>
                            <Link key={e.identifier} className={classes.item} onClick={handleClose} to={getEntityUrl({ entity: e, defaultLocale })}>
                                {e.title}
                            </Link>
                        )}
                        {locales?.length > 1 &&
                            <div key="langList">
                                <div className={classes.item} key="empty">&nbsp;</div>
                                {
                                    locales.map((locale) => {
                                        if (locale.code === curLocale) return <React.Fragment key={locale.code} />
                                        const link = getTranslatedEntityLink({ locale: locale, defaultLocale, entity });
                                        return <Link key={locale.code} className={classes.item} onClick={handleClose} to={link}>
                                            {locale.name}
                                        </Link>
                                    })}
                            </div>
                        }
                        <div key="colorMode">
                            <div>
                                <DarkmodeSwitch
                                    sx={{
                                        marginTop: '11px',
                                    }}
                                    onChange={colorMode.toggleColorMode}
                                    checked={theme.palette.mode === 'dark'}
                                />
                            </div>

                        </div>
                    </div></div>
            </Slide>
        </>}</Root>
    );
}

export default MainMenu;

const staticQuery = graphql`
    query {
                allStrapiAppSettings {
                    nodes {
                        Topbar {
                            languageSwitch
                            entities {
                                identifier
                                title
                                locale
                            }
                        }
                        logo {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 30)
                                }
                            }
                        }
                    }
                }
                allStrapiEntities {
                    nodes {
                        identifier
                        title
                        locale
                    }
                }
            }
    `;


const PREFIX = 'MainMenu';

const classes = {
    root: `${PREFIX}-root`,
    menu: `${PREFIX}-menu`,
    item: `${PREFIX}-item`,
    logo: `${PREFIX}-logo`,
    closeButton: `${PREFIX}-closeButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        backgroundColor: '#333',
        position: 'fixed',
        zIndex: 100,
        height: '100%',
        width: '100%',
        color: '#fff',
        top: '0px',
        left: '0px',
        fontFamily: 'schirnblack',
    },

    [`& .${classes.menu}`]: {
        width: '94%',
        background: 'transparent',
        height: 'auto',
        position: 'fixed',
        right: '0',
        top: '49px',
        zIndex: '8000',
        margin: '0px 10px',
        overflow: 'auto',
        maxHeight: '90vh',
    },

    [`& .${classes.item}`]: {
        fontSize: '17px',
        borderBottom: '1px solid #eee',
        paddingTop: '11px',
        paddingBottom: '11px',
        display: 'block',
        paddingLeft: '0px',
        color: '#ffffff',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: 'normal',
        zIndex: 65535
    },

    [`& .${classes.logo}`]: {

    },

    [`& .${classes.closeButton}`]: {
        flex: '0 1 auto',
        marginLeft: 'auto'

    }
}));

const DarkmodeSwitch = styled(Switch)(({ theme }) => ({
    width: 70,
    height: 34,
    padding: 7,
    paddingLeft: 1,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(0px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(30px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));