import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { navigate } from "gatsby-link";

import { styled } from '@mui/material/styles';

import _ from "lodash";

import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@mui/material";

import Carousel, { CaptionDiv } from '../Carousel';

import { getEntityUrl } from '../../util';

import ZoomWrapper from "./zoomWrapper";

const PREFIX = 'Imageslider';

const classes = {
    playerFullscreen: `${PREFIX}-playerFullscreen`
};

const Root = styled('div')(() => ({
    marginBottom: '6px',

    [`& .${classes.playerFullscreen}`]: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    }
}));

const Imageslider = (props) => {
    const { component, files, fullscreenMode, pageContext } = props;

    const images = useMemo(() => {
        const images = [];
        _.each(component.images, i => images.push(_.find(files.nodes, { id: i.localFile___NODE })));
        return images;
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);

    component.duration = parseInt(component.duration);

    const carouselProps = {
        infiniteLoop: true,
        fullHeightHover: false,
        interval: (component.duration || 3) * 1000,
        autoPlay: component.duration !== 0,
        showThumbs: false,
        showStatus: false,
        swipeScrollTolerance: 30,
        swipeable: component.zoomable !== true,
        // TODO Swipe funktioniert mit dem setting oben nicht deshalb geändert
        // Swipe kann mit zoom nicht sinnvoll funktionieren - daher ist es konditional aus, wenn zoom an ist! ( !== heisst wenn zoomable ungleich true ist, ist es an) 
    };

    const [playerStyle, setPlayerStyle] = useState('');
    useEffect(() => {
        setPlayerStyle(fullscreenMode ? classes.playerFullscreen : '')
    }, [fullscreenMode]);

    const hasCaption = component.images?.some(i => i.caption !== '');

    const { defaultLocale } = pageContext;
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale }));
        else if (fullscreenMode) navigate(-1);
    }

    return (<Root>
        <FullscreenWrapper {...props} >
            {
                images.length > 0 && (
                    images.length === 1 ?
                        <div className="imageslider">
                            <ZoomWrapper image={images[0]} caption={component.images[0].caption} zoomable={component.zoomable} fullscreenMode={fullscreenMode} />

                        </div>
                        :
                        <Carousel carouselProps={carouselProps} className="imageslider" hasCaption={hasCaption} fullscreenMode={fullscreenMode}>
                            {images.map((i, n) =>
                                <Box key={i.id} align="center">
                                    <ZoomWrapper image={i} caption={component.images[n].caption} zoomable={component.zoomable} fullscreenMode={fullscreenMode} />
                                </Box >
                            )}
                        </Carousel >
                )
            }
            {
                audioUrl !== undefined &&
                <div className={playerStyle}>
                    <Player style={{ paddingTop: '12px' }} width='100%' height='42px'
                        url={audioUrl}
                        controls={true}
                        loop={false /* TODO - make configurable */}
                        playing={component.autoplayAudio || false}
                        playsinline={component.fullscreen || false}
                        onEnded={onEnded}
                    />
                </div>
            }

        </FullscreenWrapper >
    </Root >
    );
}

export default Imageslider;